import React from 'react';
import moment from 'moment';
import { graphql, useStaticQuery } from 'gatsby';

import * as StatusListstyles from './StatusList.module.scss';
import Status from './Status';
import { StatusEdge, StatusQuery } from '../types';

const StatusList: React.FC = () => {
  const now = moment();

  const statusData: StatusQuery = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: {regex: "/(status)/.*\\.md$/"} },
        sort: {fields: frontmatter___startDate, order: ASC}
        ) {
        edges {
          node {
            frontmatter {
              startDate
              endDate
              title
            }
            excerpt (pruneLength: 500)
          }
        }
      }
    }
  `);

  const onlineStatus: StatusEdge[] = statusData.allMarkdownRemark.edges
    .filter(({ node: { frontmatter: { endDate, startDate } } }) => (
      moment(now).isBetween(startDate, endDate, null, '[]')
    ));

  const upcomingStatus: StatusEdge[] = statusData.allMarkdownRemark.edges
    .filter(({ node: { frontmatter: { startDate } } }) => (
      moment(now).isBefore(startDate)
    ))
    .filter((_, index) => index < 4);

  return (
    <div className={StatusListstyles.status}>
      <div className={StatusListstyles.bottom}>
        {onlineStatus.length > 0 && <h3>Online status</h3>}
        {onlineStatus.map((edge: StatusEdge) => {
          const { excerpt, frontmatter: { title, startDate, endDate } } = edge.node;
          return (
            <Status
              key={title}
              startDate={moment(startDate).format('ddd MMM D, hh:mm A')}
              endDate={moment(endDate).format('ddd MMM D, hh:mm A')}
              title={title}
              description={excerpt}
            />
          );
        })}
      </div>
      <div className={StatusListstyles.bottom}>
        {upcomingStatus.length > 0 && <h3>Upcoming events</h3>}
        {upcomingStatus.map((edge: StatusEdge) => {
          const { excerpt, frontmatter: { title, startDate, endDate } } = edge.node;
          return (
            <Status
              key={title}
              startDate={moment(startDate).format('MMM D, YYYY, hh:mm A')}
              endDate={moment(endDate).format('MMM D, YYYY')}
              title={title}
              description={excerpt}
            />
          );
        })}
      </div>
    </div>
  );
};

export default StatusList;
