import React from 'react';

import * as statusStyles from './Status.module.scss';

interface StatusProps {
  startDate: string,
  title: string,
  description: string,
  endDate: string
}

const Status: React.FC<StatusProps> = ({ startDate, title, description, endDate }: StatusProps) => {
  return (
    <div>
      <p className={statusStyles.date}>{startDate} - {endDate}</p>
      <p className={statusStyles.title}>{title}</p>
      <p className={statusStyles.description}>{description}</p>
    </div>
  );
};

export default Status;
